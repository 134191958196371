<template>
  <div class="fixed-containter">
    <div class="d-flex">
      <div class="flex mx-auto p-4 mt-4 header-container flex-grow">
        <div class="flex">
          <img
            style="object-fit: contain; width: 60px"
            :src="require('../assets/logo-1.png')"
          />
          <div class="my-auto" style="margin-left: 10px; font-weight: bold; color: white">
            <div>Bitcoin</div>
            <div style="font-size: 20px">Halving Coin</div>
          </div>
        </div>
        <div v-if="$vuetify.breakpoint.mdAndUp" class="my-auto mx-auto flex">
          <div
            v-for="(item, index) in menu"
            :key="index"
            class="mx-3"
            style="color: white; font-weight: 500"
            @click="item.click"
          >
            {{ item.text }}
          </div>
        </div>
        <div @click="onBuyClick" class="my-auto ml-auto">
          <div v-if="!$vuetify.breakpoint.smAndDown" class="buy-button">Buy $BCHVC</div>
        </div>
        <div class="my-auto ml-3" v-if="$vuetify.breakpoint.smAndDown">
          <v-icon @click="() => (showMenu = !showMenu)" color="white">mdi-menu</v-icon>
        </div>
      </div>
    </div>
    <div v-if="showMenu && $vuetify.breakpoint.smAndDown" style="background-color: black">
      <div
        v-for="(item, index) in menu"
        :key="index"
        class="mx-3 p-3"
        style="color: white; font-weight: 500"
        @click="item.click"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      menu: [
        {
          text: "Home",
          click: () => {
            this.$router.push("/");
            this.showMenu = false;
          },
        },
        {
          text: "How it works",
          click: () => {
            this.$router.push("/");
            location.href = "#t";
            this.showMenu = false;
          },
        },
        {
          text: "Tokenomics",
          click: () => {
            this.$router.push("/");
            location.href = "#a";
            this.showMenu = false;
          },
        },
        {
          text: "Roadmap",
          click: () => {
            this.$router.push("/");
            location.href = "#f";
            this.showMenu = false;
          },
        },

        {
          text: "Whitepaper",
          click: () => {
            this.$router.push("/whitepaper");
            this.showMenu = false;
          },
        },
      ],
    };
  },
  methods: {
    onBuyClick() {
      window.location.href = "https://bitchaintify.com";
    },
  },
};
</script>

<style lang="scss" scoped>
.fixed-containter {
  position: fixed;
  width: 100%;
}
.header-container {
  background-color: #0a1725;
  border-radius: 40px;
}
.buy-button {
  padding: 10px 25px;
  border-radius: 30px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
  font-weight: 500;
}
</style>
