<template>
  <v-app  style="overflow: hidden">
    <v-main id="vue-body" style="overflow: hidden">
      <Header />
      <router-view />
    </v-main>
  </v-app>
</template>
<script>
import Header from "./components/Header.vue";
export default {
  components: {
    Header,
  },
};
</script>
<style lang="scss">
#vue-body {
  height: 100%;
  width: 100%;
  background-color: #212121;
}
</style>
