<template>
  <div >
    <TopBanner />
    <AddressSection />
    <FeatureSection />
    <VideoSection />
  </div>
</template>

<script>
import TopBanner from "./TopBanner.vue";
import AddressSection from "./AddressSection.vue";
import FeatureSection from "./FeatureSection.vue";
import VideoSection from "./VideoSection.vue";
export default {
  data() {
    return {};
  },
  components: {
    TopBanner,
    AddressSection,
    FeatureSection,
    VideoSection,
  },
};
</script>

<style scoped>
.background-image-container {
  height: auto;
  width: 100%;
  border-radius: 0px 0 100px 100px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-header {
  border-radius: 0 0 60px 60px;
  line-height: 1.2;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  padding: 10px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
}
.buy-button {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 30px;
  background: rgb(0, 87, 255);
  background: linear-gradient(197deg, rgba(0, 87, 255, 1) 0%, rgba(58, 208, 38, 1) 100%);
  color: white;
  font-weight: bold;
}
</style>
